<template>
    <div class="head case_f_head">
        <a href="javascript:void(0);" class="hd_logo">
            <img src="@/assets/head/logo.png" alt="" class="img img_pc" />
        </a>
        <em class="sj_menu"></em>
        <div class="hd_nav">
            <li><a href="/">首页</a>
            </li>
            <!-- <li><a href="">码上数据</a>
            </li> -->
            <li><a href="">防伪溯源</a>
                <div class="hd_drop">
                    <a href="/znfw">智能防伪</a>
                    <a href="/trace">农产品追溯</a>
                </div>
            </li>
            
            <!-- <li><a href="#">码上销售</a>
            </li> -->
            <li><a href="/channel">渠道营销</a>
                <!-- <div class="hd_drop">
                    <a href="">生产管理</a>
                    <a href="">渠道管理</a>
                    <a href="">销售管理</a>
                </div> -->
            </li>
            <li><a href="/case">合作案例</a>
                
            </li>
            <li><a href="/about">联系我们</a>
                <!-- <div class="hd_drop">
                    <a href="">专属定制</a>
                    <a href="/about">公司介绍</a>
                </div> -->
            </li>
        </div>
        <div class="hd_admin">
            <a href="http://www.t.151m.net/login" class="link login">登录</a>

        </div>
    </div>
</template>

<script>
export default {
    name: "Head"

}
</script>

<style>
.hd_nav > li >a{
color: black;
}
</style>