<template>
    <div class="foot">
        <div class="auto">
            <a href="javascript:void(0);" class="ft_logo">
                <img src="@/assets/foot/ft_logo.png" alt="" class="img" />
            </a>
            <div class="ft_info clearfix">
                <div class="column fl">
                    <h6 class="tit">首页</h6>
                    <a href="/znfw" class="lk">智能防伪</a>
                    <a href="/trace" class="lk">农产品追溯</a>
                </div>
                <div class="column fl">
                    <h6 class="tit">行业应用</h6>
                    <a href="/channel" class="lk">渠道营销</a>
                    <a href="/case" class="lk">合作案例</a>
                </div>
                <div class="column fl">
                    <h6 class="tit">公司简介</h6>
                    <a href="/about" class="lk">加入我们</a>
                </div>
                <div class="column fl contact">
                    <h6 class="tit">联系方式</h6>
                    <p class="font">
                        <img src="@/assets/foot/ft_01.png" alt="" class="icon" /><span>电话: 0771-3215779
                            手机：13377187529</span>
                    </p>
                    <p class="font">
                        <img src="@/assets/foot/ft_02.png" alt="" class="icon" /><span>QQ：358318018</span>
                    </p>
                    <p class="font">
                        <img src="@/assets/foot/ft_03.png" alt="" class="icon" /><span>邮箱：358318018@qq.com&nbsp;</span>
                    </p>
                    <p class="font">
                        <img src="@/assets/foot/ft_04.png" alt="" class="icon" /><span>南宁市高新区高科路8号电子产业园1号楼B座7层</span>
                    </p>
                </div>
                <div class="column fl wechart">
                        <h6 class="tit">扫一扫，微信联系</h6>
                        <div class='kongbai'></div>
                        <div class='kongbai'></div>
                        <div style='width:100%' align='left'>
                            <img src="@/assets/foot/wx.png" height='120' class="vx" />
                            <div class='kongbai'></div>
                            <div style='width:100%;color:#fff' align='left'>
                                欢迎咨询合作
                            </div>
                    </div>
                </div>
            </div>
            <div class="ft_copyr">
                Copyright © 2012- 2019 广西一物一码科技有限公司 151mt.net All Reserved 隐私权政策 备案号:<a href="http://beian.miit.gov.cn"
                    rel="nofollow" target="_blank" title="桂ICP备19005601号-1">桂ICP备19005601号-1</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Foot"

}
</script>

<style></style>