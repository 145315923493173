import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/znfw',
    name: '智能防伪',
    component: function () {
      return import('@/views/znfw/index.vue')
    }
  },
  {
    path: '/trace',
    name: '农产品溯源',
    component: function () {
      return import('@/views/nongchanpinsuyuan/index.vue')
    }
  },
  {
    path: '/case',
    name: '成功案例',
    component: function () {
      return import('@/views/chenggonganli/index.vue')
    }
  },
  {
    path: '/channel',
    name: '渠道管理',
    component: function () {
      return import('@/views/qudaoguanli/index.vue')
    }
  },
  {
    path: '/about',
    name: '关于我们',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('@/views/about/index.vue')
    }
  },
  {
    path: '/code/:code',
    component: () => import('@/views/mobile'),
    hidden: true
  },
  {
    path: '/code/',
    component: HomeView,
    hidden: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
