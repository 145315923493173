<template>
  <div class="home">
    <div class="idx_banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide banner"></div>
          <div class="swiper-slide banner3"></div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next" @click="buttonNext"></div>
        <div class="swiper-button-prev" @click="buttonPrev"></div>
      </div>
    </div>

    <div class="idx_sec1">
      <div class="auto clearfix">
        <div class="idx_sec1_l fl">
          <div class="idx_titbox">
            <h4 class="t_tit">真假通 - 不只验真假</h4>
            <h6 class="b_smtit">真假通一物一码主要功能</h6>
            <img src="@/assets/home/idx_01.png" alt="" class="b_icon" />
          </div>
          <div class="list clearfix">
            <a href="quanchengzhuisu.html" class="item item_scale">
              <img src="@/assets/home/idx_02.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">全程追溯</h6>
                <p class="font">更快、更真、更狠</p>
              </div>
            </a>
            <a href="fangweisuyuan.html" class="item item_scale">
              <img src="@/assets/home/idx_03.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">智能防伪</h6>
                <p class="font">数字防伪、物理防伪</p>
              </div>
            </a>
            <a href="qudaoguankong.html" class="item item_scale">
              <img src="@/assets/home/idx_04.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">渠道精细化</h6>
                <p class="font">智能收发货、智能防窜货、经销商分级管理</p>
              </div>
            </a>
            <a href="jingzhunyingxiao.html" class="item item_scale">
              <img src="@/assets/home/idx_05.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">智能促销</h6>
                <p class="font">实物奖品、虚拟奖品精准投放</p>
              </div>
            </a>
            <a href="yiyeziyuan.html" class="item item_scale">
              <img src="@/assets/home/idx_06.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">异业资源</h6>
                <p class="font">降低营销成本、提升用户体验</p>
              </div>
            </a>
            <a href="nongchanpinfangan.html" class="item item_scale">
              <img src="@/assets/home/idx_07.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">智慧监管</h6>
                <p class="font">网格化监管、农产品合格证、投入品监管</p>
              </div>
            </a>
            <a href="dashujufenxi.html" class="item item_scale">
              <img src="@/assets/home/idx_08.png" alt="" class="icon fl" />
              <div class="txt fl">
                <h6 class="tit">大数据分析</h6>
                <p class="font">数据可视化、分析图表</p>
              </div>
            </a>
          </div>
        </div>
        <div class="idx_sec1_r fr">
          <img src="@/assets/home/idx_09.png" alt="" class="img img_hover" />
        </div>
      </div>
    </div>

    <div class="idx_sec2">
      <div class="auto">
        <div class="idx_titbox">
          <h4 class="t_tit">精准 - 驱动一切</h4>
          <h6 class="b_smtit">
            <span>还质量安全真相</span><em class="circle"></em>
            <span>为精准营销助力</span><em class="circle"></em>
            <span>让政府监管更高效</span>
          </h6>
          <img src="@/assets/home/idx_10.png" alt="" class="b_icon" />
        </div>
        <div class="detail">
          <img src="@/assets/home/idx_11.png" alt="" class="img img_hover" />
        </div>
      </div>
    </div>

    <div class="idx_sec3">
      <div class="auto">
        <div class="idx_titbox">
          <h4 class="t_tit">专业成就领先</h4>
          <img src="@/assets/home/idx_01.png" alt="" class="b_icon" />
        </div>
        <div class="list clearfix">
          <li class="item">
            <em class="num col1">7<i>+</i></em>
            <h6 class="title">行业经验</h6>
            <p class="font">丰富的项目落地经验</p>
          </li>
          <li class="item">
            <em class="num col2">2<i>亿+</i></em>
            <h6 class="title">扫描量</h6>
            <p class="font">大体量的一物一码平台</p>
          </li>
          <li class="item">
            <em class="num col3">150<i>个/分钟</i></em>
            <h6 class="title">生产数据</h6>
            <p class="font">领先的生产线一体化软硬件系统</p>
          </li>
          <li class="item">
            <em class="num col4">3<i>+</i></em>
            <h6 class="title">三大供应商</h6>
            <p class="font">阿里、腾讯、京东</p>
          </li>
          <li class="item">
            <em class="num col5">10<i>+</i></em>
            <h6 class="title">知识产权</h6>
            <p class="font">专利和软件著作权</p>
          </li>
          <li class="item">
            <em class="num col6">0<i>风险</i></em>
            <h6 class="title">风险可控</h6>
            <p class="font">软硬件结合，持续升级</p>
          </li>
          <li class="item">
            <em class="num col7">5<i>座</i></em>
            <h6 class="title">服务中心</h6>
            <p class="font">北京、陕西、广西、湖南、新疆</p>
          </li>
          <li class="item">
            <em class="num col3">7<i>x</i>24 <i>h</i></em>
            <h6 class="title">贴心保姆</h6>
            <p class="font">保姆式售后服务与运维</p>
          </li>
        </div>
      </div>
    </div>

    <div class="idx_sec4">
      <div class="auto clearfix">
        <div class="idx_titbox">
          <h4 class="t_tit">合作共赢</h4>
          <h6 class="b_smtit">共同加入真假通产品身份证云平台</h6>
          <img src="@/assets/home/idx_01.png" alt="" class="b_icon" />
        </div>
        <div class="list clearfix">
          <p class="item">
            <img src="@/assets/home/Hidx_12.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_12-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_13.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_13-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_14.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_14-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_15.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_15-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_16.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_16-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_17.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_17-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_18.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_18-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_19.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_19-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_11.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_11-1.jpg" alt="" class="img img2" />
          </p>
          <p class="item">
            <img src="@/assets/home/Hidx_10.jpg" alt="" class="img img1" />
            <img src="@/assets/home/Hidx_10-1.jpg" alt="" class="img img2" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "@/assets/js/swiper.min.js"
export default {
  name: 'HomeView',
  components: {
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      // Swiper的配置选项
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
  methods: {
    buttonNext() {
      this.swiper.slideNext();
    },
    buttonPrev() {
      this.swiper.slidePrev();
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }
}
</script>
<style scoped>
@import url(@/assets/css/swiper.min.css);
@import url(@/assets/css/style.css);

/* 确保分页器可见 */
.swiper-pagination {
  background: #000;
  opacity: 1;
}

.banner {
  background: url(@/assets/home/idx_banner.jpg) no-repeat top center;
  background-size: cover;
}

.banner3 {
  background: url(@/assets/home/idx_banner3.jpg) no-repeat top center;
  background-size: cover;
}
</style>
